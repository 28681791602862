/* eslint-disable no-unused-vars */
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { useTranslation } from 'react-i18next';
import { Close, ContentCopy, CreditCard, Person, Pix, QrCode, WarningAmberOutlined } from '@mui/icons-material';
import {
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  IconButton,
  Link,
  MenuItem,
  Paper,
  Stack,
  Tab,
  Typography,
} from '@mui/material';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { isAfter } from 'date-fns';
import { PayPalButtons, PayPalScriptProvider } from '@paypal/react-paypal-js';
import BoxW from '../../../components/wrapper/BoxW';
import useLocation from '../../../components/hook/useLocation';
import TextFieldW from '../../../components/wrapper/TextFieldW';
import { ReactComponent as FaCcElo } from '../../../assets/image/cc-elo.svg';
import { ReactComponent as FaCcHiperCard } from '../../../assets/image/cc-hipercard.svg';
import { ReactComponent as FaCcAmex } from '../../../assets/image/cc-amex.svg';
import { ReactComponent as FaCcMastercard } from '../../../assets/image/cc-mastercard.svg';
import { ReactComponent as FaCcVisa } from '../../../assets/image/cc-visa.svg';
import { ReactComponent as PagSeguroIcon } from '../../../assets/image/pagseguro.svg';
import { ReactComponent as PayPalIcon } from '../../../assets/image/paypal.svg';
import { ReactComponent as PagSeguroSmallIcon } from '../../../assets/image/pagseguro-icon.svg';
import Selector from '../../../components/form-components/Selector';
import { useToast } from '../../../components/context/toast/ToastContext';
import RegistrationService from '../../../services/RegistrationService';
import { FlexGrow, TooltipW, useThemeChange } from '../../../components/context/ThemeChangeContext';
import { useEditionChange } from '../../../components/context/EditionChangeContext';
import ButtonW from '../../../components/wrapper/ButtonW';
import { SimpleContentDisplay } from '../../registrations/my-registration/ActivityCard';
import { useUserChange } from '../../../components/context/UserChangeContext';
import ObjectUtils from '../../../utils/ObjectUtils';
import ConditionalMaskField from '../../../components/form-components/ConditionalMaskField';
import FormUtils from '../../../utils/FormUtils';
import { CartContext, ManipulateStep } from '../registration';
// import CaravanEnrollmentService from '../../../services/CaravanEnrollmentService';
import _ from 'lodash';
import Form from '../../../components/form-components/Form';
import { useHistory } from 'react-router';
import { useCopyToClipboard } from '@uidotdev/usehooks';
import MuiDialogTitle from '@mui/material/DialogTitle';
import { LoadingButton } from '@mui/lab';

const Step4 = () => {
  const { t } = useTranslation();
  const { formatCurrency, formatLocaleString } = useLocation();
  const { addToast } = useToast();
  const { currentTheme } = useThemeChange();
  const { currentEdition } = useEditionChange();
  const { currentUser } = useUserChange();
  const [copiedText, copyToClipboard] = useCopyToClipboard();
  const history = useHistory();
  const [openDialog, setOpenDialog] = useState(false);

  const enableCardCredit = true;

  const [icon, setIcon] = useState(null);
  const [installments, setInstallments] = useState(1);
  const [interest, setInterest] = useState(0);
  const [promotion, setPromotion] = useState(0);
  const [activeTab, setActiveTab] = useState(enableCardCredit ? '1' : '3');
  const [pixData, setPixData] = useState(null);
  const [receiverInfo, setReceiverInfo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingCancel, setLoadingCancel] = useState(false);
  const [loadingPix, setLoadingPix] = useState(false);
  const [errorsCpf, setErrorsCpf] = useState({});

  const [cpfField, setCpfField] = useState('');

  const [cpfFieldCard, setCpfFieldCard] = useState('');

  const [updateRegistration, setUpdateRegistration] = useState(true);
  const [payementStatus, setPaymentStatus] = useState('');

  const [registrationData, setRegistrationData] = useState(undefined);

  const currentRegistrationHash = useMemo(() => ObjectUtils.getHash(registrationData), [registrationData]);

  useEffect(() => {
    // console.log('useEffect findOneByEditionAndUser')
    if (!currentEdition || !currentUser || !updateRegistration) return;
    setUpdateRegistration(false);
    RegistrationService.findOneByEditionAndUser(currentEdition.id, currentUser.id).then((response) => {
      if (response.status === 200) {
        if (response.data == null || response.data === '') {
          addToast({ body: t('toastes.fetchError'), type: 'error' });
          return;
        }
        const newHash = ObjectUtils.getHash(response.data);
        if (newHash !== currentRegistrationHash) {
          setRegistrationData(response.data);
        }
        return;
      }
      if (response.status === 404) {
        setRegistrationData(undefined);
        return;
      }
      addToast({ body: t('toastes.fetchError'), type: 'error' });
    });
  }, [addToast, currentEdition, currentRegistrationHash, currentUser, t, updateRegistration]);

  const { cart: products, includedActivities } = useContext(CartContext);

  const cartTotal = products.reduce((total, item) => total + item.price, 0);

  const { activeStep, setActiveStep, updateStepValues, stepValues } = useContext(ManipulateStep);

  const defaultPrice =  stepValues[2]?.registrationType === 1 ? currentEdition?.registrationType?.price / 2 : currentEdition?.registrationType?.price;

  // console.log(defaultPrice);
  // console.log("Atividades inclusas armazenadas: ", includedActivities);

  const totalPrice = useMemo(() => {
    // if (regId) {
    //   return registrationData.finalPrice;
    // }
    return defaultPrice + cartTotal;
  }, [defaultPrice, cartTotal]);


  const tabs = enableCardCredit
    ? [
        {
          id: '1',
          label: t('pages.myRegistration.payment.pagseguro'),
          enabled: true,
          icon: (
            <PagSeguroSmallIcon
              width="30px"
              height="30px"
              fill={currentTheme.palette.getContrastText(currentTheme.palette.background.paper)}
            />
          ),
        },
        {
          id: '2',
          label: t('pages.myRegistration.payment.pix'),
          enabled: true,
          icon: <Pix />,
        },
        {
          id: '3',
          label: t('pages.myRegistration.payment.paypal'),
          enabled: true,
          icon: (
            <PayPalIcon width="30px" height="30px" fill={currentTheme.palette.getContrastText(currentTheme.palette.background.paper)} />
          ),
        },
      ]
    : [
        {
          id: '3',
          label: t('pages.myRegistration.payment.paypal'),
          enabled: true,
          icon: (
            <PayPalIcon width="30px" height="30px" fill={currentTheme.palette.getContrastText(currentTheme.palette.background.paper)} />
          ),
        },
      ];

  const schemaCard = yup.object().shape({
    holder: yup.string().required(),
    number: yup
      .number()
      .required()
      .test('wrongCardNumber', '', (value) => String(value).length > 12 && String(value).length < 20),
    expMonth: yup
      .number()
      .required()
      .test('validMonth', '', (value) => value >= 1 && value <= 12)
      // eslint-disable-next-line no-use-before-define
      .test('minExpYear', '', () => validateExpireDate()),
    expYear: yup
      .number()
      .required()
      // eslint-disable-next-line no-use-before-define
      .test('minExpYear', '', () => (value) => String(value).length > 4 && validateExpireDate()),
    securityCode: yup
      .number()
      .required()
      .test('wrongSecurityCode', '', (value) => String(value).length >= 3 && String(value).length <= 4),
    cpf: yup
      .string()
      .required('CPF é obrigatório')
      .test('valid-cpf', 'CPF inválido', (value) => FormUtils.validateCPF(value)),
  });

  const { control, handleSubmit, formState, watch, reset } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schemaCard),
  });

  // const {
  //   control: controlCpf,
  //   handleSubmit: handleSubmitCpf,
  //   formState: { errors: errorsCpf },
  //   // reset: resetPix,
  // } = useForm({
  //   mode: 'onBlur',
  //   resolver: yupResolver(cpfSchema),
  // });

  const handleChangeInstallments = (event) => {
    setInstallments(event.target.value);
  };

  const handleBack = () => {
    reset();
    handleRegistrationCancellation({ isBack: true });
    setActiveStep(activeStep - 1);
  };

  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
    if (newValue !== 3) {
      setInstallments(1);      //tira o juros caso o usuário saia da aba de cartão de crédito
    }
  };

  // const { cart: products } = useContext(CartContext);

  const totalWithInterestValues = useMemo(() => {
    const values = {};
    [1, 2, 3, 4, 5, 6].forEach((value) => {
      const interestRates = {
        1: 0.0,
        2: 0.0526,
        3: 0.0706,
        4: 0.0887,
        5: 0.1071,
        6: 0.1256,
      };

      const totalWithInterest = totalPrice + totalPrice * interestRates[value];
      values[value] = totalWithInterest;
    });
    return values;
  }, [totalPrice]);

  const number = watch('number', undefined);
  const expYear = watch('expYear', undefined);
  const expMonth = watch('expMonth', undefined);

  const { errors } = formState;

  const validateExpireDate = () => {
    if (expYear && expYear?.length !== 4 && expMonth) return true;

    const cardExpDate = new Date(`${expMonth}/30/${expYear}`);

    return !isAfter(Date.now(), cardExpDate);
  };

  /*   const handleRegistration = useCallback(() => {
    if (currentEdition && currentUser) {
      const form = {
        edition: currentEdition,
        user: currentUser,
        individualRegistrations: products.map((activity) => ({ activity: { id: activity.id } })),
      };
      // console.log(form);
      RegistrationService.create(form).then((response) => {
        // console.log(response.data);
        if (response.status === 200) {
          setUpdateRegistration(true);
          if (response.data.payed && response.data.finalPrice === 0.0) {
            setPaymentStatus(t('pages.myRegistration.payment.paymentStatus.voucherFound'));
          }
        } else if (response.status >= 400 && response.status <= 500) {
          // addToast({ body: t('toastes.saveError'), type: 'error' });
          console.log(response.data);
        }
      });
    }
  }, [currentEdition, currentUser, products, setPaymentStatus, t]); 

  useEffect(() => {
    if (!updateRegistration) {
      handleRegistration();
    }
  }, [handleRegistration, updateRegistration]);
  */

  useEffect(() => {
    // console.log('useEffect number')
    if (number) {
      const size = '80%';
      if (
        number.match(
          /^4011(78|79)|^43(1274|8935)|^45(1416|7393|763([12]))|^504175|^627780|^63(6297|6368|6369)|(65003[5-9]|65004[0-9]|65005[01])|(65040[5-9]|6504[1-3][0-9])|(65048[5-9]|65049[0-9]|6505[0-2][0-9]|65053[0-8])|(65054[1-9]|6505[5-8][0-9]|65059[0-8])|(65070[0-9]|65071[0-8])|(65072[0-7])|(65090[1-9]|6509[1-6][0-9]|65097[0-8])|(65165[2-9]|6516[67][0-9])|(65500[0-9]|65501[0-9])|(65502[1-9]|6550[34][0-9]|65505[0-8])|^(506699|5067[0-6][0-9]|50677[0-8])|^(509[0-8][0-9]{2}|5099[0-8][0-9]|50999[0-9])|^65003[1-3]|^(65003[5-9]|65004\d|65005[0-1])|^(65040[5-9]|6504[1-3]\d)|^(65048[5-9]|65049\d|6505[0-2]\d|65053[0-8])|^(65054[1-9]|6505[5-8]\d|65059[0-8])|^(65070\d|65071[0-8])|^65072[0-7]|^(65090[1-9]|65091\d|650920)|^(65165[2-9]|6516[6-7]\d)|^(65500\d|65501\d)|^(65502[1-9]|6550[3-4]\d|65505[0-8])[0-9]+$/
        )
      ) {
        // ELO
        setIcon(<FaCcElo width={size} height={size} />);
      } else if (
        number.match(/^(4).+$/) &&
        !number.match(/^((451416)|(438935)|(40117[8-9])|(45763[1-2])|(457393)|(431274)|(402934))[0-9]+$/)
      ) {
        // Visa
        setIcon(<FaCcVisa width={size} height={size} />);
      } else if (number.match(/^((34)|(37))[0-9]+$/)) {
        // Amex
        setIcon(<FaCcAmex width={size} height={size} />);
      } else if (
        number.match(/^(5[1-5][0-9]{2}|222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[01][0-9]|2720)[0-9]{12}$/) &&
        !number.match(
          /^(514256|514586|526461|511309|514285|501059|557909|553839|525855|553777|553771|551792|528733|549180|528745|517562|511849|557648|546367|501070|601782|508143|501085|501074|501073|501071|501068|501066|589671|589633|588729|501089|501083|501082|501081|501080|501075|501067|501062|501061|501060|501058|501057|501056|501055|501054|501053|501051|501049|501047|501045|501043|501041|501040|501039|501038|501029|501028|501027|501026|501025|501024|501023|501021|501020|501018|501016|501015|589657|589562|501105|557039|542702|544764|550073|528824|522135|522137|562397|566694|566783|568382|569322|504363)[0-9]+$/
        )
      ) {
        // Master Card
        setIcon(<FaCcMastercard width={size} height={size} />);
      } else if (number.match(/^((606282)|(637095)|(637568)|(637599)|(637609)|(637612))[0-9]+$/)) {
        // HiperCard
        setIcon(<FaCcHiperCard width={size} height={size} />);
      }
    } else {
      setIcon(null);
    }
  }, [number]);
  // console.log(registrationData);
  const regId = registrationData?.id;

  useEffect(() => {
    // console.log('useEffect get promotion')
    if (regId && !promotion)
      RegistrationService.getPromotion(regId).then((regResp) => {
        if (regResp.status === 200) {
          setPromotion(currentEdition?.registrationType?.price * (regResp.data.percentage / 100));
        }
      });
  }, [regId, promotion, defaultPrice, currentEdition?.registrationType?.price]);

  const handleCardDetails = (form) => {
    // console.log("Handle Details")
    // console.log('handleCardDetails', form);
    setLoading(true);
    const cardForm = form;
    // console.log('cardForm', cardForm);
    const cpf = form.cpf.replace(/\D/g, '');
    cardForm.publicKey = process.env.REACT_APP_PAGSEGURO_PUBLIC_KEY;
    const card = window.PagSeguro.encryptCard(cardForm);
    // console.log(card);
    if (!card.hasErrors) {
      RegistrationService.charge(regId, { card: card.encryptedCard, installments, cpf, interest }).then((chargeResponse) => {
        if (chargeResponse.status === 200) {
          addToast({ body: t('toastes.chargeSuccess'), type: 'success' });
          setPaymentStatus(t('pages.myRegistration.payment.paymentStatus.chargeSuccess'));
          reset({}, { keepValues: false });
          localStorage.removeItem('cartItems');
          setActiveStep(activeStep + 1);
          setLoading(false);
        } else if (chargeResponse.status >= 400 && chargeResponse.status <= 500) {
          addToast({ body: t('toastes.chargeError'), type: 'error' });
        }
        setLoading(false);
      });
    } else {
      console.log(card.errors);
      setLoading(false);
      addToast({ body: t('toastes.cardError'), type: 'error' });
    }
  };

  const handleCancelCardDetails = () => {
    addToast({ body: t('toastes.paymentCancel'), type: 'warning' });

    RegistrationService.cancelPayment(regId).then((resp) => {
      if (resp.status === 200) {
        // addToast({ body: t('toastes.chargeCancel'), type: 'success' });
        reset();
        setPaymentStatus(t('pages.myRegistration.payment.paymentStatus.cancelSuccess'));
        handleBack();
        return;
      }
      addToast({ body: t('toastes.deleteError'), type: 'error' });
    });
  };

  useEffect(() => {
    // console.log('useEffect getPix')
    if (receiverInfo === null) {
      RegistrationService.getReceiverPix().then((resp) => {
        if (resp.status === 200) {
          setReceiverInfo(resp.data);
          return;
        } else {
          addToast({ body: t('toastes.fetchError'), type: 'error' });
        }
      });
    }
  }, [addToast, receiverInfo, t]);

  const handlePixDetails = () => {
    setLoadingPix(true);
    // console.log('handlePixDetails called');
    // console.log(cpfField);
    // console.log(errorsCpf);
    if (cpfField === '') {
      setErrorsCpf({ cpf: { message: 'campo requerido' } });
      setLoadingPix(false);
      return;
    } else if (FormUtils.validateCPF(cpfField) === false) {
      setErrorsCpf({ cpf: { message: 'CPF inválido' } });
      setLoadingPix(false);
      return;
    } else {
      setErrorsCpf(undefined);
    }

    let items = products
      .map((item) => {
        return {
          name: item.name,
          quantity: 1,
          unit_amount: item.price,
        };
      })
      .filter((item) => item.unit_amount !== 0);

    let defaultPrice = {
      name: currentEdition?.shortName,
      quantity: 1,
      unit_amount: currentEdition?.registrationType?.price,
    };

    let payload = {
      customer: {
        tax_id: cpfField?.replace(/[.-]/g, ''),
      },
      reference_id: String(regId),
      items: defaultPrice.unit_amount > 0 ? [defaultPrice, ...items] : items,
      qr_codes: [
        {
          amount: {
            value: totalPrice.toFixed(2).toString(),
          },
          expiration_date: new Date(Date.now() + 24 * 60 * 60 * 1000 + 60 * 60 * 1000).toISOString().toString(),
        },
      ],
    };

    // console.log(payload);
    // console.log(regId);

    if (regId) {
      RegistrationService.charge(regId, payload).then((chargeResponse) => {
        // console.log(chargeResponse);
        if (chargeResponse?.data?.status === 201) {
          addToast({ body: t('toastes.chargeSuccess'), type: 'success' });
          setPaymentStatus(t('pages.myRegistration.payment.paymentStatus.chargeSuccess'));
          setLoadingPix(false);
          updateStepValues(6, { ...chargeResponse.data.data, regId: regId });
          localStorage.removeItem('cartItems');
          history.push('/registration/4');
          setPixData(chargeResponse.data.data);
        } else if (chargeResponse.status >= 400 && chargeResponse.status <= 500) {
          setLoadingPix(false);
          addToast({ body: t('toastes.chargeError'), type: 'error' });
        }
      });
    } else {
      setLoadingPix(false);
      addToast({ body: t('toastes.chargeError'), type: 'error' });
    }
  };

  // console.log(pixData);

  const handleOpenDialog = (activity) => {
    setOpenDialog(true);
  };

  const handleRegistrationCancellation = (options = { isBack: false }) => {
    setLoadingCancel(true);
    RegistrationService.cancelPayment(regId).then((resp) => {
      // console.log(resp);
      if (resp.status === 200) {
        setPaymentStatus(undefined);
        RegistrationService.cancelRegistration(regId).then((response) => {
          // console.log(response);
          if (response.status === 200) {
            addToast({ body: t('toastes.discarded'), type: 'success' });
            setUpdateRegistration(true);
            localStorage.removeItem('cartItems');
            setLoadingCancel(false);
            if (!options.isBack) {
              history.push('/home');
            }
            // setActivitiesPrice(0);
            // setConflictedActivities([]);
            // setConflictedActivitiesOnLectures([]);
            // setSelectedActivities([]);
          } else if (response.status >= 400 && response.status <= 500) {
            setLoadingCancel(false);
            addToast({ body: t('toastes.discardedError'), type: 'error' });
          }
        });
        return;
      }
      addToast({ body: t('toastes.discardedError'), type: 'error' });
    });
  };

  const createPayPalOrder = () => {
    if (regId)
      return RegistrationService.createOrder(regId).then((response) => {
        if (response.status === 200) {
          return response.data;
        }
        addToast({ body: t('toastes.chargeError'), type: 'error' });
        return null;
      });
    return null;
  };

  const Summary = ({ isPagSeguro }) => (
    <Paper elevation={3} sx={{ p: 3 }}>
      <Typography variant="h6">{t('pages.myRegistration.payment.summary')}</Typography>
      <Divider />
      <Grid container spacing={1} my={1} maxHeight={200} sx={{ overflowY: 'scroll' }}>
        <Grid item xs={12} display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
          <Typography fontWeight="bold" fontSize="20">
            {t('pages.myRegistration.lecturePackCard.title')}
          </Typography>
          <Typography fontSize="15">{formatCurrency(defaultPrice)} </Typography>
        </Grid>
        {products?.map((product) => (
          <Grid
            item
            xs={12}
            display={'flex'}
            flexDirection={'row'}
            justifyContent={'space-between'}
            key={product.name}
            alignItems={'center'}
          >
            <TooltipW title={product.name} placement="top" arrow>
              <Typography fontWeight="bold" fontSize="20" sx={{ lineHeight: 1.2 }}>
                {product.name.length > 30 ? `${product.name.substring(0, 30)}...` : product.name}
              </Typography>
            </TooltipW>
            <Typography component={'span'} fontSize="15">
              {formatCurrency(product.price)}{' '}
            </Typography>
          </Grid>
        ))}
      </Grid>
      <Divider />
      <Grid container spacing={1} py={1}>
        <Grid item xs={12} display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
          <Typography fontWeight="bold" fontSize="18">
            {t('pages.myRegistration.payment.subTotal')}
          </Typography>
          <Typography>{formatCurrency(promotion ? totalPrice + promotion : totalPrice)}</Typography>
        </Grid>
        {isPagSeguro && activeTab === '1' && (
          <>
            <Grid item xs={12} display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
              <Typography fontWeight="bold" fontSize="18">
                {t('pages.myRegistration.payment.interest')}
              </Typography>
              <Typography>{formatCurrency(totalWithInterestValues[installments] - totalPrice)}</Typography>
              {/* Verifica se o valor está definido antes de setInterest */}
              {totalWithInterestValues[installments] &&
                totalPrice &&
                setInterest((totalWithInterestValues[installments] - totalPrice).toFixed(2))}
            </Grid>
            <Divider />
            <Grid item xs={12} display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
              <Typography fontWeight="bold" fontSize="20">
                {t('pages.myRegistration.payment.quantityInstallments')}
              </Typography>
              <Typography>{installments}x</Typography>
              {''}
            </Grid>
          </>
        )}
        <Grid item xs={12} display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
          <Typography fontWeight="bold" fontSize="18">
            {t('pages.myRegistration.payment.promotion')}
          </Typography>
          <Typography>{formatCurrency(promotion || 0)}</Typography>
        </Grid>
        
      </Grid>
      <Divider />
      <Stack direction="row" justifyContent="space-between" alignItems="center" pt={1}>
        <Typography fontWeight="bold" fontSize="20">
          {t('pages.myRegistration.payment.total')}
        </Typography>
        <Typography>{formatCurrency(totalWithInterestValues[installments])}</Typography>{' '}
      </Stack>
      {isPagSeguro && (
        <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="space-between" alignItems="center" pt={1}>
          <Typography sx={{ lineHeight: 1.2 }}>{t('pages.myRegistration.payment.pagSeguroText')}</Typography>{' '}
          <PagSeguroIcon width="45%" height="45%" />
        </Stack>
      )}
    </Paper>
  );

  return (
    <Box>
      <Typography variant="h6" fontWeight="bold" pb={4}>
        {t('pages.registration.step4.title')}
      </Typography>
      <Box
        py={2}
        px={{ xs: 1, sm: 2 }}
        xs={12}
        sx={{
          background: 'background.default',
          borderRadius: '16px',
          boxShadow: 'inset 0px 1px 8px 0px #878787',
        }}
      >
        {loading ? (
          <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '400px' }}>
            <CircularProgress />
            <Typography mt={2}>{t('pages.registration.step4.loading')}</Typography>
          </Box>
        ) : (
          <TabContext value={activeTab}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handleChange} aria-label="lab API tabs example">
                {tabs.map((tab, index) => (
                  <Tab label={tab.label} icon={tab.icon} value={tab.id} key={tab.id} color="primary" />
                ))}
              </TabList>
            </Box>
            <TabPanel value="1" disabled sx={{ px: { xs: 1, sm: 3 }, py: 2 }}>
              <Form key={1} onSubmit={handleSubmit(handleCardDetails)}>
                <Grid container spacing={{ xs: 2, md: 4 }}>
                  <Grid item xs={12} md={7} ml={{ xs: 0, md: -3 }}>
                    <Paper elevation={3} sx={{ px: 3, py: 2 }}>
                      <Typography variant="h6" mb={1}>
                        {t('pages.myRegistration.payment.cardDetails')}
                      </Typography>
                      <Grid container spacing={{ xs: 2, md: 2 }}>
                        <Grid item xs={12}>
                          <Controller
                            name="holder"
                            render={({ field }) => (
                              <TextFieldW
                                label={t('pages.myRegistration.payment.holder')}
                                {...field}
                                prefix={<Person />}
                                error={errors?.holder}
                                required
                              />
                            )}
                            control={control}
                          />
                        </Grid>
                        <Grid item xs={6} display={'flex'} direction={'row'} gap={1} alignItems={'flex-start'}>
                          <Controller
                            name="cpf"
                            control={control}
                            render={({ field }) => (
                              <ConditionalMaskField
                                condition={'BR'}
                                maskFieldProps={{
                                  mask: FormUtils.cpfMask,
                                  error: errors.cpf,
                                }}
                                {...field}
                                onChange={(e) => {
                                  field.onChange(e);
                                }}
                                required
                                label={t('pages.myAccount.nin')}
                                value={field.value} // Utiliza o valor de field para manter sincronização
                                textFieldProps={{
                                  error: !!errors.cpf,
                                  required: true,
                                }}
                                helperText={errors.cpf?.message}
                              />
                            )}
                          />
                        </Grid>

                        <Grid item xs={6} display={'flex'} direction={'row'} gap={1} alignItems={'flex-start'}>
                          <Controller
                            name="number"
                            render={({ field }) => (
                              <TextFieldW
                                label={t('pages.myRegistration.payment.number')}
                                placeholder="XXXX-XXXX-XXXX-XXXX"
                                {...field}
                                prefix={<CreditCard />}
                                error={errors?.number}
                                required
                              />
                            )}
                            control={control}
                          />
                          {icon && (
                            <BoxW minWidth="50px" width={{ xs: '25%', md: '18%' }} mr={-3} mt={{ xs: 2, md: 0 }}>
                              {icon}
                            </BoxW>
                          )}
                        </Grid>
                        <Grid item xs={12} md={9}>
                          <Typography mb={1}>{t('pages.myRegistration.payment.expires')}</Typography>
                          <Box width={{ xs: '100%', md: '50%' }} display="flex" flexDirection="row" gap={1}>
                            <Stack direction="row" spacing={1}>
                              <Controller
                                name="expMonth"
                                render={({ field }) => (
                                  <TextFieldW
                                    label={t('pages.myRegistration.payment.expMonth')}
                                    {...field}
                                    error={errors?.expMonth}
                                    placeholder="MM"
                                    required
                                  />
                                )}
                                control={control}
                              />
                              <Controller
                                name="expYear"
                                render={({ field }) => (
                                  <TextFieldW
                                    label={t('pages.myRegistration.payment.expYear')}
                                    {...field}
                                    error={errors?.expYear}
                                    placeholder="AAAA"
                                    required
                                  />
                                )}
                                control={control}
                              />
                            </Stack>
                          </Box>
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <Typography mb={1}>{t('pages.myRegistration.payment.securityCode')}</Typography>
                          <Controller
                            name="securityCode"
                            render={({ field }) => (
                              <TextFieldW
                                label={t('pages.myRegistration.payment.cvv')}
                                {...field}
                                error={errors?.securityCode}
                                placeholder="000"
                                required
                              />
                            )}
                            control={control}
                          />
                        </Grid>
                      </Grid>
                      <Grid item xs={12} mt={2}>
                        <Selector
                          label={t('pages.myRegistration.payment.possibleInstallment')}
                          required
                          value={installments}
                          onChange={(e) => handleChangeInstallments(e)}
                        >
                          {Object.keys(totalWithInterestValues).map((key, index) => {
                            const exactInstallmentValue = totalWithInterestValues[key] / key;
                            const installmentValue = (Math.ceil(exactInstallmentValue * 100) / 100).toFixed(2);
                            const totalWithInterest = ((totalWithInterestValues[key] * 100) / 100).toFixed(2);
                            return (
                              <MenuItem key={index} value={key}>
                                {`${key} parcela(s) de R$ ${installmentValue} - Total com juros: R$ ${totalWithInterest}`}
                              </MenuItem>
                            );
                          })}
                        </Selector>
                      </Grid>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} md>
                    <Summary isPagSeguro />
                    <Stack mt={4} sx={{ width: '100%' }} direction="row" spacing={2}>
                      <ButtonW fullWidth error onClick={handleCancelCardDetails} sx={{ fontSize: 20, fontWeight: 'bold', py: 1.5 }}>
                        {t('pages.registration.step4.textButtonCancelPayment')}
                      </ButtonW>
                      <ButtonW fullWidth color="success" type="submit" sx={{ fontSize: 20, fontWeight: 'bold', py: 1 }}>
                        {t('pages.registration.step4.textButtonConfirmOrder')}
                      </ButtonW>
                    </Stack>
                  </Grid>
                </Grid>
              </Form>
            </TabPanel>
            <TabPanel value="2" sx={{ px: { xs: 1, sm: 3 }, py: 2 }}>
              <Grid container spacing={{ xs: 2, md: 4 }}>
                <Grid item xs={12} md={7} ml={{ md: -3, xs: 0 }}>
                  <Paper elevation={3} sx={{ p: 3 }}>
                    <Form key={2}>
                      <Typography variant="h6">{t('pages.myRegistration.payment.pixDetails')}</Typography>
                      <Typography variant="body2" paddingBottom={2}>
                        {t('pages.registration.step1.column2.msgRequeriment')}
                      </Typography>
                      <Box pb={2} width={{ xs: '100%', md: '50%' }}>
                        {/* <Controller
                          name="cpf"
                          render={({ field }) => ( */}
                        <ConditionalMaskField
                          condition={'BR'}
                          maskFieldProps={{
                            mask: FormUtils.cpfMask,
                            error: errorsCpf?.cpf,
                          }}
                          onChange={(e) => setCpfField(e.target.value)}
                          required
                          label={t('pages.myAccount.nin')}
                          value={cpfField}
                          textFieldProps={{
                            error: errorsCpf && errorsCpf?.cpf,
                            required: true,
                          }}
                          helperText={errorsCpf?.cpf?.message}
                        />
                        {/* )}
                          control={controlCpf}
                        /> */}
                      </Box>
                      <BoxW>
                        {receiverInfo && (
                          <>
                            <SimpleContentDisplay
                              leftItem={t('pages.myRegistration.payment.receiver.name')}
                              rightItem={receiverInfo.name}
                            />
                            {/* <SimpleContentDisplay leftItem={t('pages.myRegistration.payment.receiver.key')} rightItem={receiverInfo.key} /> */}
                          </>
                        )}
                      </BoxW>
                      {loadingPix && (
                        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: 300 }}>
                          <CircularProgress />
                          <Typography mt={2}>{t('pages.registration.step4.loading')}</Typography>
                        </Box>
                      )}
                      {pixData ? (
                        <BoxW>
                          <Typography fontWeight="bold" py={1}>
                            {t('pages.myRegistration.payment.pixCopyText')}
                          </Typography>
                          <Box p={1} bgcolor="#cAcAcA" sx={{ borderRadius: 1 }}>
                            <Typography fontSize={12}>{pixData?.pixCopyPaste}</Typography>
                          </Box>
                          <Box display={'flex'} mt={1} justifyContent={'center'}>
                            <ButtonW
                              variant="contained"
                              color="primary"
                              size="small"
                              startIcon={<ContentCopy />}
                              sx={{ borderRadius: 28, fontSize: 12, px: 3 }}
                              onClick={() => {
                                copyToClipboard(pixData?.pixCopyPaste);
                                if (copiedText !== '') {
                                  addToast({ body: 'Código copiado!', type: 'success' });
                                }
                              }}
                            >
                              {t('pages.myRegistration.payment.copyToClipboard')}
                            </ButtonW>
                          </Box>
                          <BoxW
                            pt={1}
                            width="100%"
                            display="flex"
                            flexDirection="column"
                            justifyContent="center"
                            alignItens="center"
                            pb={0}
                          >
                            <Typography fontWeight="bold">{t('pages.myRegistration.payment.pixQrCode')}</Typography>
                            <Typography>
                              Leia o QR Code abaixo para realizar o pagamento, voce tem até {formatLocaleString(pixData?.pixExpirationDate)}
                              :
                            </Typography>
                            <img
                              src={`https://sandbox.api.pagseguro.com/qrcode/${pixData?.qrcodeId}/png`}
                              width="300px"
                              height="300px"
                              alt="QrCode"
                              style={{ marginLeft: 'auto', marginRight: 'auto', marginTop: '8px' }}
                            />
                          </BoxW>
                        </BoxW>
                      ) : (
                        <BoxW display="flex" flexDirection="row" width="100%" justifyContent="flex-end">
                          <BoxW p={1}>
                            <FlexGrow />
                          </BoxW>
                          <BoxW p={1} display="flex" justifyContent="center" pl={0}>
                            {/* <ButtonW autoFocus primary onClick={handlePixDetails} sx={{ fontWeight: 'bold', py: 2, px: 4 }}>
                              <QrCode sx={{ mr: 1 }} />
                              {t('pages.myRegistration.payment.sendPix')}
                            </ButtonW> */}
                          </BoxW>
                        </BoxW>
                      )}
                    </Form>
                  </Paper>
                </Grid>
                <Grid item xs={12} md>
                  <Summary isPagSeguro />
                  <Stack mt={4} sx={{ width: '100%' }} direction="row" spacing={2}>
                    <ButtonW fullWidth error onClick={handleRegistrationCancellation} sx={{ fontSize: 20, fontWeight: 'bold', py: 1.5 }}>
                      {t('pages.registration.step4.textButtonCancelPayment')}
                    </ButtonW>
                    <ButtonW fullWidth color="success" onClick={handlePixDetails} sx={{ fontSize: 20, fontWeight: 'bold', py: 1 }}>
                      {t('pages.registration.step4.textButtonConfirmOrder')}
                    </ButtonW>
                  </Stack>
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value="3" sx={{ px: { xs: 1, sm: 3 }, py: 2 }}>
              <Grid container spacing={4}>
                <Grid item xs={12} md={7}>
                  <Box p={1} height="100%" display="flex" flexDirection="column" alignContent="center" paddingLeft={2}>
                    <PayPalScriptProvider
                      options={{
                        'client-id': process.env.REACT_APP_PAYPAL_CLIENT_ID,
                        components: 'buttons',
                        currency: 'BRL',
                      }}
                    >
                      <BoxW minWidth="50%" border="1px" paddingTop={2}>
                        {regId && (
                          <PayPalButtons
                            style={{ color: 'gold', shape: 'pill', height: 55 }}
                            disabled={false}
                            fundingSource={undefined}
                            createOrder={() => createPayPalOrder()}
                            onApprove={(data, actions) =>
                              actions.order.capture().then((algo) => {
                                RegistrationService.saveOrder(regId, algo).then((resp) => {
                                  if (resp.status === 200) {
                                    reset();
                                    setPaymentStatus(t('pages.myRegistration.payment.paymentStatus.chargeSuccess'));
                                    setActiveStep(activeStep + 1);
                                    localStorage.removeItem('cartItems');
                                  }
                                });
                              })
                            }
                          />
                        )}
                      </BoxW>
                    </PayPalScriptProvider>
                  </Box>
                </Grid>
                <Grid item xs={12} md={5} mt={4}>
                  <Summary />
                </Grid>
              </Grid>
            </TabPanel>
          </TabContext>
        )}
      </Box>
      <Box
        width="100%"
        mt={2}
        py={{ xs: 0, sm: 2 }}
        px={{ xs: 0, sm: 4 }}
        flexDirection="row"
        display="flex"
        flexWrap="wrap"
        alignItems="center"
        justifyContent="end"
      >
        <Box p={1} width={{ xs: '100%', sm: '21%' }}>
          <ButtonW fullWidth color="warning" onClick={handleOpenDialog} sx={{ fontSize: 20, fontWeight: 'bold', py: 2 }}>
            {t('pages.registration.step4.textButtonCancelRegistration')}
          </ButtonW>
        </Box>
        <Box p={1} width={{ xs: '100%', sm: '21%' }}>
          <ButtonW fullWidth secondary onClick={handleBack} sx={{ fontSize: 20, fontWeight: 'bold', py: 2 }}>
            {t('pages.editSatisfactionSurvey.back')}
          </ButtonW>
        </Box>
      </Box>
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <MuiDialogTitle
          disableTypography
          sx={(theme) => ({
            margin: 0,
            padding: 2,
          })}
        >
          <Typography sx={{ paddingLeft: '8px', width: '90%' }} variant="h6">
            {t('pages.registration.step4.dialog.title')}
          </Typography>
          <IconButton
            aria-label="close"
            sx={(theme) => ({
              position: 'absolute',
              right: theme.spacing(1),
              top: theme.spacing(1),
              color: theme.palette.text.primary || currentTheme.palette.getContrastText(currentTheme.palette.appBar.backgroundColor),
            })}
            onClick={() => setOpenDialog(false)}
          >
            <Close />
          </IconButton>
        </MuiDialogTitle>
        <DialogContent
          sx={(theme) => ({
            padding: 5,
            paddingBottom: 0,
          })}
        >
          <Box display="flex" flexDirection="column" alignItems="center">
            <WarningAmberOutlined color="warning" sx={{ mb: 2, fontSize: '10em' }} />
            <Typography textAlign={'center'} gutterBottom>
              {t('pages.registration.step4.dialog.text')}
              <br />
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <ButtonW autoFocus onClick={() => setOpenDialog(false)} error>
            {t('pages.registration.step4.dialog.buttonCancel')}
          </ButtonW>
          <LoadingButton
            variant="contained"
            color="secondary"
            autoFocus
            loading={loadingCancel}
            onClick={() => handleRegistrationCancellation()}
            primary
          >
            {t('pages.registration.step4.dialog.buttonConfirm')}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Step4;
