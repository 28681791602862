import React, { useEffect, useMemo, useState } from 'react';
import { Box, Button, CardActions, CardContent, CardHeader, Chip, IconButton, Paper, Popover, Typography } from '@mui/material';
import * as Colors from '@mui/material/colors';
import _ /* , { round } */ from 'lodash';
import { CalendarMonth, ExpandMore } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

// import TrackInfoCard from './TrackInfoCard';
import useRTE from '../hook/useRTE';
import useLocation from '../hook/useLocation';
import FileService from '../../services/FileService';
import CustomDialog from '../custom-dialog/CustomDialog';
import BoxW from '../wrapper/BoxW';
import Scheduler from '../scheduler/Scheduler';
import { TooltipW } from '../context/ThemeChangeContext';
import SpeakerInfoCard from '../../pages/registrations/my-registration/SpeakerInfoCard';
import FlagIcon from '../flag-icon/FlagIcon';

export const SimpleContentDisplay = (props) => {
  const { leftItem, rightItem, width = '100%', textColor, isIcon } = props;

  return (
    <Box alignItems="center" display="flex" flexWrap="wrap" flexDirection="row" width={width} sx={{ color: textColor }}>
      {leftItem && !isIcon ? (
        <Box paddingRight={1}>
          <Typography fontWeight="bold">{`${leftItem}:`}</Typography>
        </Box>
      ) : (
        <Box paddingRight={1}>
          <Typography fontWeight="bold">{leftItem}</Typography>
        </Box>
      )}
      <Box>{rightItem}</Box>
    </Box>
  );
};

const CardActivity = ({ activity, notActions = false, minWidth = '300px', minHeight = '300px', buttonOptions, showDetails = true }) => {
  const [events, setEvents] = useState([]);
  const { renderFromState } = useRTE();
  const { formatCurrency } = useLocation();
  const { t } = useTranslation();
  const [openScheduleDialog, setOpenScheduleDialog] = useState(false);


  const [expanded, setExpanded] = useState(false);

  const avatarColor = useMemo(() => _.sample(Colors)[500], []);

  const [speakerSrc, setSpeakerSrc] = useState({});

  useEffect(() => {
    activity.speakers.forEach(({ speaker }) => {
      if (speaker.userProfile && speaker.src == null) {
        FileService.findOne(speaker.userProfile.id).then((response) => {
          if (response.status === 200) {
            // eslint-disable-next-line no-param-reassign
            speaker.src = URL.createObjectURL(
              new Blob([response.data], { type: `image${speaker.userProfile.format === 'svg' ? '/svg+xml' : ''};charset=utf-8` })
            );
            speakerSrc[speaker.id] = speaker.src;
            setSpeakerSrc(_.clone(speakerSrc));
          }
        });
      }
    });
  }, [activity, speakerSrc]);

  useEffect(() => {
    if (activity.schedule.length > 0) {
      setEvents(
        activity.schedule.map(({ startDateTime, endDateTime, allDay, title, color }) => ({
          start: startDateTime,
          end: endDateTime,
          allDay,
          title,
          color,
        }))
      );
    }
  }, [activity.schedule]);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  // console.log(activity.description?.blocks[0].text);
  // console.log('activity', activity.track);
  return (
    <>
      <CustomDialog
        dialogProps={{ maxWidth: 'lg' }}
        open={openScheduleDialog}
        onClose={() => setOpenScheduleDialog(false)}
        title={t('pages.myRegistration.scheduleTitle')}
        content={
          <BoxW width="100%">
            <Scheduler
              {...{
                events,
                setEvents,
              }}
              readOnly
              validRange={{
                start: activity?.track?.initialDate,
                end: activity?.track?.finalDate,
              }}
            />
          </BoxW>
        }
      />
      <Paper
        // ref={ref}
        elevation={3}
        sx={(theme) => ({
          padding: theme.spacing(2),
          borderRadius: 1.3,
          textAlign: 'center',
          display: 'flex',
          // width: '100%',
          minWidth: minWidth,
          minHeight: minHeight,
          flexDirection: 'column',
          justifyContent: 'space-between',
        })}
      >
        <CardHeader
          // avatar={
          //   <AvatarGroup max={2} variant="circular">
          //     {activity.speakers.map(({ speaker }) => (
          //       <Avatar key={speaker.id} sx={{ bgcolor: avatarColor }} src={speakerSrc[speaker.id]}>
          //         {speaker.name[0]}
          //       </Avatar>
          //     ))}
          //   </AvatarGroup>
          // }
          // action={!readOnly && <CheckboxW onChange={(e) => onCheck && onCheck(activity, e.target.checked)} primary />}
          title={
            <>
              <TooltipW title={activity?.name} placement="bottom" arrow>
                {activity.name.length > 30 ? `${activity.name.substring(0, 30)}...` : activity.name}
              </TooltipW>
            </>
          }
          sx={{ p: 0 }}
          subheader={
            <TooltipW title={activity?.track?.name} placement="bottom" arrow>
              <Chip
                label={activity?.track?.name?.length > 50 ? `${activity?.track?.name.substring(0, 40)}...` : activity?.track?.name ?? 'N/A'}
                sx={(theme) => ({
                  backgroundColor: activity?.track?.calendarColor,
                  color: activity?.track?.calendarColor ? theme.palette.getContrastText(activity?.track?.calendarColor) : 'black',
                })}
                size="small"
              />
            </TooltipW>
          }
        />
        <CardContent
          sx={{
            height: '50px',
            display: 'flex',
            flexDirection: 'column',
            textAlign: 'center',
            alignItems: 'center',
            justifyContent: 'center',
            pt: 4,
          }}
        >
          {activity.description?.blocks[0]?.text !== '' ? (
            <>
              <TooltipW title={t('pages.myRegistration.activityCard.tooltipDescription')} placement="top" arrow>
                <Box sx={{ cursor: 'pointer', lineHeight: '1.2', pb: 2 }} onClick={(e) => handleClick(e)}>
                  {`${activity.description?.blocks[0]?.text.substring(0, 50)}...`}
                </Box>
              </TooltipW>
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                sx={{ '& .MuiPopover-paper': { sx: { width: '80%' }, md: { width: '40%' } } }}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
              >
                <Box p={3}>
                  <strong>{t('pages.userActivities.activityCard.description')}:</strong>
                  {renderFromState(activity.description)}
                </Box>
              </Popover>
            </>
          ) : (
            <Box sx={{ alignItems: 'center' }}>{t('pages.myRegistration.activityCard.noDescription')}</Box>
          )}
        </CardContent>
        <CardContent sx={{ p: 0 }}>
          <Typography fontWeight="bold">{activity.price === 0 ? t('pages.registration.step2.card.free') : formatCurrency(activity.price)}</Typography>
          {showDetails && (
            <TooltipW title={expanded ? 'Ver menos' : 'Ver mais'} placement="top" arrow>
              <Button
                // sx={(theme) => ({
                //   transform: !expanded ? 'rotate(0deg)' : 'rotate(180deg)',
                //   marginLeft: 'auto',
                //   transition: theme.transitions.create('transform', {
                //     duration: theme.transitions.duration.complex,
                //   }),
                // })}
                sx={{ my: 1 }}
                variant="text"
                size="small"
                onClick={() => setExpanded(!expanded)}
                endIcon={
                  <ExpandMore
                    sx={(theme) => ({
                      transform: !expanded ? 'rotate(0deg)' : 'rotate(180deg)',
                      marginLeft: 'auto',
                      transition: theme.transitions.create('transform', {
                        duration: theme.transitions.duration.complex,
                      }),
                    })}
                  />
                }
              >
                {t('pages.registration.step2.card.details')}
              </Button>
            </TooltipW>
          )}
        </CardContent>
        {expanded && showDetails && (
          <Box width="100%" display="flex" flexWrap="wrap" flexDirection="column">
            {/* <SimpleContentDisplay
                leftItem={t('pages.myRegistration.activityCard.track')}
                rightItem={<TrackInfoCard track={activity.track} />}
              /> */}
            {/* <SimpleContentDisplay
              leftItem={t('pages.myRegistration.activityCard.responsible')}
              rightItem={
                activity.responsibleUser ? (
                  <SpeakerInfoCard speakers={[activity.responsibleUser]} avatarColor={avatarColor} />
                ) : (
                  <Typography sx={(theme) => ({ color: theme.palette.error.main })}>
                    {t('pages.myRegistration.activityCard.noResponsible')}
                  </Typography>
                )
              }
            /> */}
            <SimpleContentDisplay
              leftItem={t('pages.myRegistration.activityCard.speakers')}
              rightItem={<SpeakerInfoCard speakers={activity.speakers} avatarColor={avatarColor} />}
            />
            <SimpleContentDisplay
              leftItem={t('pages.myRegistration.activityCard.vacancies')}
              rightItem={`${activity.remainingVacancies}/${activity.vacancies}`}
            />
            <SimpleContentDisplay leftItem={t('pages.myRegistration.activityCard.workload')} rightItem={activity.workload} />
            <SimpleContentDisplay
              leftItem={t('pages.myRegistration.activityCard.type')}
              rightItem={t(`enums.activityTypes.${activity.type}`)}
            />
            <SimpleContentDisplay
              leftItem={t('pages.myRegistration.activityCard.presentationType')}
              rightItem={t(`enums.editionTypes.${activity.presentationType}`)}
            />
            {activity.presentationType === 'LIVE' && (
              <SimpleContentDisplay leftItem={t('pages.myRegistration.activityCard.place')} rightItem={activity.place.name} />
            )}
            <SimpleContentDisplay
              leftItem={t('pages.myRegistration.activityCard.language')}
              rightItem={
                <Box display="flex" alignItems="center">
                  {activity.language}
                  {activity.languageFlag && (
                    <Box paddingLeft={1}>
                      <FlagIcon country={activity.languageFlag} height="25px" />
                    </Box>
                  )}
                </Box>
              }
            />
          </Box>
        )}
        {!notActions && (
          <CardActions disableSpacing sx={{ display: 'flex', justifyContent: 'center', p: 0 }}>
            {activity.schedule?.length > 0 && (
              <TooltipW title={t('pages.myRegistration.activityCard.calendarTooltip')} placement="top" arrow>
                <IconButton onClick={() => setOpenScheduleDialog(true)}>
                  <CalendarMonth color='primary' fontSize="large" />
                </IconButton>
              </TooltipW>
            )}
            <Button
              onClick={buttonOptions.onClick}
              variant="contained"
              color={buttonOptions.color || 'secondary'}
              size="large"
              sx={{ fontWeight: 'bold' }}
              endIcon={buttonOptions.icon}
              fullWidth
              disabled={buttonOptions.disabled || false}
            >
              {buttonOptions.title}
            </Button>
          </CardActions>
        )}
      </Paper>
    </>
  );
};

export default CardActivity;
