import { useTranslation } from 'react-i18next';
import useLocation from '../hook/useLocation';
import { useClientRect } from '../../pages/registrations/my-registration/ActivityCard';
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { Box, Button, colors, Grid, IconButton, Paper, Tooltip, Typography } from '@mui/material';
import { SimpleContentDisplay } from './CardActivity';
import CustomDialog from '../custom-dialog/CustomDialog';
import BoxW from '../wrapper/BoxW';
import Scheduler from '../scheduler/Scheduler';
import { TooltipW } from '../context/ThemeChangeContext';
import { CalendarToday, ExpandMore } from '@mui/icons-material';
import SpeakerInfoCard from '../../pages/registrations/my-registration/SpeakerInfoCard';
import FlagIcon from '../flag-icon/FlagIcon';
import _ from 'lodash';

const CardMainActivity = (props) => {
  const { activities, activity, onCardHeightChange, conflicts, minWidth = '312px', enableHalfPrice = false, buttonOptions, registration_payed = false } = props;

  // const { renderFromState } = useRTE();
  const { formatCurrency, formatLocaleDateString, formatLocaleTimeString } = useLocation();
  const { t } = useTranslation();

  const [rect, ref] = useClientRect();

  const [expanded, setExpanded] = useState(false);
  const [openScheduleDialog, setOpenScheduleDialog] = useState(false);
  const [events, setEvents] = useState(false);
  const [selectedActivity, setSelectedActivity] = useState(null);
  const [selectedButtonIndex, setSelectedButtonIndex] = useState(null);

  const [opIncludedActivityInfo, setOpIncludedActivityInfo] = useState(false);
  const [includedActivityData, setIncludedActivityData] = useState(null);
  const avatarColor = useMemo(() => _.sample(colors)[500], []);
  // const [buttonDisabled, setButtonDisabled] = useState(false);

  useEffect(() => {
    if (onCardHeightChange && rect && !expanded) {
      onCardHeightChange(rect.height);
    }
  }, [expanded, onCardHeightChange, rect]);

  useEffect(() => {
    if (conflicts.length > 0) setExpanded(true);
    else setExpanded(false);
  }, [conflicts.length]);

  const handleActivitySelected = (activ) => {
    setOpenScheduleDialog(true);
    setSelectedActivity(activ);

    setEvents(
      activ.schedule.map(({ startDateTime, endDateTime, allDay, title, color }) => ({
        start: startDateTime,
        end: endDateTime,
        allDay,
        title,
        color,
      }))
    );
  };

  const assembleGrid = ({ schedule }) => {
    return (
      <>
        <SimpleContentDisplay leftItem={'Data'} rightItem={formatLocaleDateString(schedule.startDateTime)} />
        {new Date(schedule.startDateTime).getHours() === 0 && new Date(schedule.endDateTime).getHours() === 0 ? (
          <>
            <Typography sx={(theme) => ({ color: theme.palette.error.main })}>Atividade ocorrerá o dia todo</Typography>
            <SimpleContentDisplay leftItem={'Início'} rightItem={'07:00'} />
            <SimpleContentDisplay leftItem={'Fim'} rightItem={'21:30'} />
          </>
        ) : (
          <>
            <SimpleContentDisplay leftItem={'Início'} rightItem={formatLocaleTimeString(schedule.startDateTime)} />
            <SimpleContentDisplay leftItem={'Fim'} rightItem={formatLocaleTimeString(schedule.endDateTime)} />
          </>
        )}
      </>
    );
  };

  // const [anchorEl, setAnchorEl] = useState(null);

  // const open = Boolean(anchorEl);
  // const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <CustomDialog
        dialogProps={{ maxWidth: 'lg' }}
        open={openScheduleDialog}
        onClose={() => setOpenScheduleDialog(false)}
        title={t('pages.myRegistration.scheduleTitle')}
        content={
          <BoxW width="100%">
            <Scheduler
              {...{
                events,
                setEvents,
              }}
              readOnly
              validRange={{
                start: selectedActivity?.track?.initialDate,
                end: selectedActivity?.track?.finalDate,
              }}
            />
          </BoxW>
        }
      />
      <Paper
        ref={ref}
        elevation={3}
        sx={(theme) => ({
          textAlign: 'center',
          padding: theme.spacing(2),
          minWidth,
        })}
      >
        <Grid container alignItems={'center'} justifyContent={'space-between'}>
          <Grid item xs={12} md={4}>
            <Typography textAlign={'left'} pl={2}>
              {activity.name}
            </Typography>
          </Grid>
          <Grid item xs={12} md={enableHalfPrice ? 4 : 4} display={'flex'} flexDirection={'column'} justifyItems="center" py={0}>
            <Typography>{activity.description}</Typography>
            <TooltipW title={expanded ? 'Ver menos' : 'Ver mais'} placement="top" arrow>
              <Button
                sx={{ mt: 1 }}
                variant="text"
                size="small"
                onClick={() => setExpanded(!expanded)}
                endIcon={
                  <ExpandMore
                    sx={(theme) => ({
                      transform: !expanded ? 'rotate(0deg)' : 'rotate(180deg)',
                      marginLeft: 'auto',
                      transition: theme.transitions.create('transform', {
                        duration: theme.transitions.duration.complex,
                      }),
                    })}
                  />
                }
              >
                {t('pages.registration.step2.card.showMore')}
              </Button>
            </TooltipW>
          </Grid>

      {enableHalfPrice ? (
        <Grid item xs={12} md={4} display={'flex'} gap={2} justifyContent={'flex-end'}>
          {buttonOptions.map(({ title, icon, onClick, color }, index) => (
            <TooltipW
              color={index === 0 ? 'error' : undefined}
              title={
                index === 0 
                  ? "Atenção: ao escolher esta opção você deverá apresentar um documento que comprove que está elegível a meia entrada"
                  : "Inscrição inteira"
              }
              placement="top"
              arrow
              key={index}
            >
              <Button
                onClick={() => {
                  setSelectedButtonIndex(index);
                  onClick(); 
                }}
                fullWidth
                variant="contained"
                color={color}
                size="large"
                sx={{
                  fontWeight: 'bold',
                  px: 4,                  
                  backgroundColor: selectedButtonIndex === index ? 'success.main' : undefined,
                  '&.Mui-disabled': {
                    backgroundColor: 'success.main',
                    color: '#fff',
                  }
                }}
                endIcon={icon}
                disabled={selectedButtonIndex === index}
              >
                <Typography fontWeight={'bold'}>
                  {title}
                </Typography>
              </Button>
            </TooltipW>
          ))}
        </Grid>
          ) : (
            <Grid item xs={12} md={4} display={'flex'} gap={2} justifyContent={'flex-end'}>
              <Typography fontWeight={'bold'} textAlign={'right'}>
                {formatCurrency(activity.price)}
              </Typography>
            </Grid>
          )}
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            {expanded && (
              <Box width="100%" display="flex" flexWrap="wrap" maxHeight={'400px'} overflow={'auto'} flexDirection="row">
                {activities.map((activityAux) => (
                  <Fragment key={activityAux.id}>
                    <BoxW display="flex" width="100%" alignItems="center">
                      <Button
                        variant="text"
                        color={'inherit'}
                        fullWidth
                        onClick={() => {
                          setOpIncludedActivityInfo(true);
                          //Organizando, em ordem cronológica, as datas das atividades
                          activityAux.schedule = activityAux.schedule.sort((a, b) => new Date(a.startDateTime) - new Date(b.startDateTime));
                          setIncludedActivityData(activityAux);
                        }}
                      >
                        <SimpleContentDisplay rightItem={activityAux.name} />
                      </Button>
                      <Tooltip title={t('pages.myRegistration.tooltip.calendar')}>
                        <IconButton onClick={() => handleActivitySelected(activityAux)}>
                          <CalendarToday />
                        </IconButton>
                      </Tooltip>
                    </BoxW>
                  </Fragment>
                ))}
              </Box>
            )}
          </Grid>
        </Grid>
      </Paper>
      {opIncludedActivityInfo && includedActivityData !== null && includedActivityData !== undefined && (
        <CustomDialog
          open={opIncludedActivityInfo}
          onClose={() => {
            setOpIncludedActivityInfo(false);
            setIncludedActivityData(undefined);
          }}
          title={includedActivityData.name}
          content={
            <BoxW width="100%">
              <SimpleContentDisplay
                leftItem={t('pages.myRegistration.activityCard.responsible')}
                rightItem={
                  includedActivityData.responsibleUser ? (
                    <SpeakerInfoCard speakers={[includedActivityData.responsibleUser]} avatarColor={avatarColor} />
                  ) : (
                    <Typography sx={(theme) => ({ color: theme.palette.error.main })}>
                      {t('pages.myRegistration.activityCard.noResponsible')}
                    </Typography>
                  )
                }
              />
              <SimpleContentDisplay
                leftItem={t('pages.myRegistration.activityCard.speakers')}
                rightItem={<SpeakerInfoCard speakers={includedActivityData.speakers} avatarColor={avatarColor} />}
              />
              <SimpleContentDisplay leftItem={t('pages.myRegistration.activityCard.workload')} rightItem={includedActivityData.workload} />
              <SimpleContentDisplay
                leftItem={t('pages.myRegistration.activityCard.type')}
                rightItem={t(`enums.activityTypes.${includedActivityData.type}`)}
              />
              <SimpleContentDisplay
                leftItem={t('pages.myRegistration.activityCard.presentationType')}
                rightItem={t(`enums.editionTypes.${includedActivityData.presentationType}`)}
              />

                {(includedActivityData.presentationType === 'LIVE' || includedActivityData.presentationType === 'HYBRID') &&
                    (<SimpleContentDisplay
                        leftItem={t('pages.myRegistration.activityCard.local')}
                        rightItem={includedActivityData.presentationType === 'LIVE' || includedActivityData.presentationType === 'HYBRID' || registration_payed
                            ? t(`${includedActivityData.place.name}`)
                            : t(`pages.myRegistration.activityCard.presentationRemote`)
                        }
                    />)
                }

                {(includedActivityData.presentationType === 'HYBRID' || includedActivityData.presentationType === 'ONLINE') && (
                    <SimpleContentDisplay
                        leftItem={t('pages.userActivities.activityCard.webPlace')}
                        rightItem={registration_payed ? includedActivityData.placeUrl.length > 0 ? includedActivityData.placeUrl : t('pages.userActivities.activityCard.linkNotProvided')
                            :
                            <Typography align={"left"} sx={(theme) => ({color: theme.palette.error.main})}>
                                {`${t('pages.userActivities.activityCard.availabilityLink')}`}
                            </Typography>
                        }
                    />
                )}

              <SimpleContentDisplay
                leftItem={t('pages.myRegistration.activityCard.language')}
                rightItem={
                  <Box display="flex" alignItems="center">
                    {includedActivityData.language}
                    {includedActivityData.languageFlag && (
                      <Box paddingLeft={1}>
                        <FlagIcon country={includedActivityData.languageFlag} height="25px" />
                      </Box>
                    )}
                  </Box>
                }
              />

              {includedActivityData.schedule.length > 0 && (
                <>
                  <Typography width={'100%'} align={'center'} fontSize={25} fontWeight={'bold'} marginTop={'30px'} marginBottom={'30px'}>
                    Programação da Atividade
                  </Typography>
                  <Grid container px={3} pt={1} spacing={2} justifyContent={'space-between'} columns={{ xs: 4, sm: 8, md: 12 }}>
                    {includedActivityData.schedule.map((schedule, index) => (
                      <Grid item xs={6} sm={6} md={6} lg={6} key={index}>
                        <Fragment key={index}>{assembleGrid({ schedule: schedule })}</Fragment>
                      </Grid>
                    ))}
                  </Grid>
                </>
              )}

              {includedActivityData.schedule.length <= 0 && (
                <Typography sx={(theme) => ({ color: theme.palette.error.main })}>
                  {t('pages.myRegistration.activityCard.noSchedule')}
                </Typography>
              )}
            </BoxW>
          }
        />
      )}
    </>
  );
};

export default CardMainActivity;
